
import { Component, Vue, Watch, Prop } from "vue-property-decorator";

@Component({
  name: "InfomationNews",
})
export default class InfomationNews extends Vue {
  @Prop() lang!: string;
  @Watch("$route", { immediate: true, deep: true })
  routeWatch(newVal: any): void {
    document.documentElement.scrollTop = 0;
  }
}
